import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchPosts from "../components/searchPosts"
import Newsletter from "../components/newsletter"


class Blog extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Writings" />
        <h1 style={{ fontWeight: 800, marginTop: 20 }} >Writings </h1>
        <p> I write about topics that I'm interested in like product design, brand strategy, marketing, philosophy, creative entrepreneurship, mental models, & more. You can subscribe to my <Link to="/join/">newsletter</Link> to receive these notes in your inbox. Use the search below to filter by title.</p>
         <SearchPosts
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />
        <hr/>
        <div className="BlogSub">
        <h3>Sign up for my newsletter <span role="img" aria-label>💌</span> </h3>
        <p>If you're interested in these topics, you should sign up for my newsletter, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on business, life & tech. </p>
        <Newsletter/>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
